import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="container-full contact">
      <div className="pageTitle">
        <h1>Entre em Contacto</h1>
      </div>
      <div className="slide contacts">
        <div className="title hide"></div>
        <div className="description">
          <div><b>Email: </b>geral@minecode.io</div>
          <div><b>Morada: </b>Rua da Quinta, nº 41, R/C A, Outeiro de Caldelas, 2420-123, Caranguejeira, Leiria</div>
          <div><b>Tel.: </b>913965690</div>
          <div><b>Tel.: </b>913965017</div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
