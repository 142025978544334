import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";

const Product = () => {
    return (
        <div className="container-full product">
            <div className="pageTitle">
                <h1>Advantage</h1>
                <Link to="/contact" className="cta-button">Peça o seu orçamento</Link>
            </div>
            <div className="slide whatIs">
                <div className="title">
                    O que é?
                </div>
                <div className="description">
                    O sistema Advantage, desenvolvido pela Minecode, é uma solução inovadora para a contagem de pontos em jogos de padel. Projetado para proporcionar uma experiência de jogo mais eficiente e precisa, o sistema Advantage combina tecnologia avançada com facilidade de uso.
                </div>
            </div>
            <div className="slide components">
                <div className="description">
                    <p>
                        <b>Televisão de Exibição:</b> Exibe de forma clara e visível o resultado atual do jogo, incluindo pontos, sets e outras informações essenciais.
                    </p>
                    <p>
                        <b>Controlador:</b> Unidade central que gerencia a contagem e exibição dos pontos. Instalado próximo da televisão e alimentado por um transformador fornecido.
                    </p>
                    <p>
                        <b>Caixas de Botões:</b> Duas unidades situadas junto à entrada do court, permitindo a interação fácil e rápida com o sistema durante o jogo.
                    </p>
                </div>
                <div className="title">
                    Componentes
                </div>
            </div>
            <div className="slide tracking">
                <div className="description">
                    Acompanhamento ao segundo de todos os jogos que estão a decorrer e ainda visualizar os próximos jogos.
                </div>
                <div className="title">
                    Acompanhamento
                </div>
            </div>
            <div className="slide setup">
                <div className="title">
                    Configurável
                </div>
                <div className="description">
                    <p>
                        Através do modo de configuração, é possível ajustar rapidamente o número de sets e o modo de jogo entre "Golden Point" e "Advantage". Isso permite uma personalização fácil e rápida para diferentes formatos de jogo.

                    </p>
                </div>
            </div>
            <div className="slide advantages">
                <div className="description">
                    <p>
                        <b>Interação Intuitiva:</b> As Caixas de Botões são projetadas para facilitar a interação durante o jogo. Com botões dedicados para marcação de pontos, remoção de pontos e finalização do jogo, os utilizadores podem operar o sistema de forma eficiente e sem complicações.
                    </p>
                    <p>
                        <b>Modos de Jogo Flexíveis:</b> O sistema permite a seleção entre "Golden Point" e "Advantage", adaptando-se às preferências e regras específicas de diferentes torneios e partidas.
                    </p>
                </div>
                <div className="title">
                    Vantagens
                </div>
            </div>
            <div className="slide advantages2">
                <div className="title">
                    Vantagens
                </div>
                <div className="description">
                    <p>
                        <b>Ajuste do Número de Sets:</b> O sistema possibilita a configuração do número de sets entre 3 e 5, tornando-o adequado para diversos formatos de competições e treinos.
                        <p>
                        </p>
                        <b>Flexibilidade e Personalização:</b> O sistema Advantage permite a integração com outros automatismos através do seu Controlador, de acordo com as necessidades específicas de cada cliente. Isso possibilita a personalização e expansão do sistema para incluir funcionalidades adicionais, como sistemas de iluminação ou outras soluções tecnológicas que possam melhorar a experiência e a operação dos jogos.
                    </p>
                </div>

            </div>

        </div>
    );
};

export default Product;
