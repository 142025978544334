import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from '../images/logo_sem_bordas_white.svg'

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false)
    return (
        <nav className="navbar">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt={"Minecode"} height={50} />
                </Link>
            </div>
            <ul className="nav-links">
                <li><Link to="/about">Sobre Nós</Link></li>
                <li><Link to="/product">Advantage</Link></li>
                <li><Link to="/contact">Contacto</Link></li>
            </ul>
            <div className="burger-menu" onClick={() => {setShowMenu(true)}}>
                <div className="burger-line"></div>
                <div className="burger-line"></div>
                <div className="burger-line"></div>
            </div>
            {showMenu && <div className="showMenuModal">
                <div className="menuModalHeader">
                    <div className="menuModalClose" onClick={() => {setShowMenu(false)}}>x</div>
                </div>
                <ul className="burger-links">
                    <li><Link onClick={() => {setShowMenu(false)}} to="/about">Sobre Nós</Link></li>
                    <li><Link onClick={() => {setShowMenu(false)}} to="/product">Advantage</Link></li>
                    <li><Link onClick={() => {setShowMenu(false)}} to="/contact">Contacto</Link></li>
                </ul>
            </div>}
        </nav>
    );
};

export default Navbar;
