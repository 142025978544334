import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import logo from '../images/logo_sem_bordas_white.svg'

const Home = () => {
  return (
      <div className="container-full home">
        <div className="pageTitle">
          <img src={logo} alt="Minecode"/>
          <p>Mining ideas, coding solutions.</p>
          <Link to="/product" className="cta-button">Saiba Mais</Link>
        </div>
        <div className="slide services">
          <div className="title">
            Serviços
          </div>
          <div className="description"> 
            Oferecemos soluções personalizadas que atendem às necessidades exclusivas de cada cliente. Os nossos serviços combinam experiência técnica com uma abordagem orientada para resultados, garantindo que cada projeto seja entregue com a mais alta qualidade e eficiência.
          </div>
        </div>
        <div className="slide advantage">
          <div className="description"> 
            <Link to="/contact" className="cta-button">Pedir demonstração</Link>
          </div>
          <div className="title">
            Advantage
          </div>
        </div>

        <div className="slide whyUs">
          <div className="title"> 
            <h2>Porquê a Minecode?</h2>
          </div>
          <div className="description">
            <span><b>Equipa especializada:</b> Contamos com profissionais experientes e atualizados com as mais recentes tecnologias.</span>
            <span><b>Soluções escaláveis:</b> Desenvolvemos sistemas prontos para crescer junto com o seu negócio.</span>
            <span><b>Acompanhamento contínuo:</b> Fornecemos suporte técnico e manutenção para garantir o funcionamento ideal das soluções implementadas.</span>
          </div>
        </div>
      </div>
      
   
  );
};

export default Home;
